import React from "react"
import { graphql, Link } from "gatsby"

import '../css/calculator-form.css';

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrinterIcon from '../images/printer-icon';
import CalcIcon from '../images/calc-icon';


class CalculatorFormInsurance extends React.Component {

  constructor(props) {
    super(props);

    if(props.fields && typeof props.fields.shipmentValue !== 'undefined')
      this.state = props.fields;
    else
      this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    let message = this.validateField(name, value);

    if(value)
      value = parseFloat(value);

    if(message !== '') {
      this.setState({[name]: {value: value, hasError: true, errorMessage: message}});
    } else {
      this.setState({[name]: {value: value, hasError: false}});
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let state = this.state;
    let validSubmission = true;

    for (const elem in state) {
      let obj = state[elem];

      if(obj.isRequired !== true){
        continue;
      }

      let message = this.validateField(elem, obj.value);

      if(message !== '') {

        if(!obj.value && obj.value !== '')
          obj.value = parseFloat(obj.value)

        validSubmission = false;
        state[elem] = {value: obj.value, hasError: true, errorMessage: message};
      }
    }

    if(!validSubmission) {
      this.setState(state);
      return;
    }

    for (const elem in state) {
      let obj = state[elem];
    }

    state.insuredValue.value = (state.shipmentValue.value + state.costFreight.value) * (state.extraExpense.value / 100 + 1);

    state.totalPremium.value = (state.insuredValue.value / 100) * state.cargoRate.value;

    this.setState(state);
  }

  handleReset(event) {
    this.setState(this.defaultState());
  }

  validateField(name, value) {
    if(!value)
        return'Please enter a number';

    let intValue = parseFloat(value.toString().replace(/[^a-zA-Z0-9]/g, ''));

    if(isNaN(intValue)) {
      return 'Please enter a valid number';
    }

    return ''
  }

  checkNumber(x) {

    // check if the passed value is a number
    if(typeof x == 'number' && !isNaN(x)){

      return true;

    } else {
      return false;
    }
  }

  defaultState() {
    return {
      shipmentValue: {
        value: '',
        hasError: false,
        isRequired: true,
      },
      costFreight: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      extraExpense: {
        value: 10,
        hasError: false,
        isRequired: true,
      },
      cargoRate: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      insuredValue: {
        value:'0.00',
      },
      totalPremium: {
        value:'0.00',
      },

    };
  }

  currencyFormat(num) {
    if(num === '0.00')
      return num;

    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    const { disclaimer, phoneNumber, printURL } = this.props;

    let state = this.state;


    return (
      <React.Fragment>
         <div className="calc-wrapper insurance-form">
          <div className="calc-tab">
            <div className="title">
              <h2>Cargo Insurance</h2>
              <h5>Calculator</h5>
            </div>
            <div className="icon"><CalcIcon /></div>
          </div>
        <form className="calcForm insurance-form" onSubmit={this.handleSubmit} onReset={this.handleReset}>
         
         <div className="three-col">
         
         <div className="col first-col">
         <div className="label-with-help">
          <label htmlFor="shipmentValue">Shipment Value:</label>
          <div className="hover-icon closer-placement"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>For new general merchandise the typical shipment value is the full commercial invoice amount of the goods being shipped.</p>
              </div>
          </div>
            <div className="has-dollar-sign">
              <input id="shipmentValue" type="number" className={`${state.shipmentValue.hasError ? "error" : ""}`} name="shipmentValue" title="value" placeholder="Value" value={state.shipmentValue.value} onChange={this.handleChange} />
            </div>
            { state.shipmentValue.hasError && <p className="error-message">{state.shipmentValue.errorMessage}</p> }
          
          </div>
          <div className="col second-col">
          <div className="label-with-help">
          <label htmlFor="costFreight">Cost of Freight:</label>
          <div className="hover-icon closer-placement"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>In the event of a loss, the cost of freight charged on the shipment can be covered by the insurance policy if it is included in the calculation.</p>
              </div>
          </div>
          <div className="has-dollar-sign">
            <input id="costFreight" type="number" className={`${state.costFreight.hasError ? "error" : ""}`} name="costFreight" title="cost" placeholder="Cost of Freight" value={state.costFreight.value} onChange={this.handleChange} />
          </div>
          { state.costFreight.hasError && <p className="error-message">{state.costFreight.errorMessage}</p> }
            </div>
            <div className="col third-col">
            <div className="label-with-help">
          <label htmlFor="extraExpense">Extra Expense: (%)</label>
          <div className="hover-icon closer-placement"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>This amount is meant to reimburse the cargo owner the cost of logistics, clerical work, or other time or expenses related to the shipment and is usually set at 10%.</p>
              </div>
          </div>
          <input id="extraExpense" type="number" className={`${state.extraExpense.hasError ? "error" : ""}`} name="extraExpense" title="extraexpense" placeholder="Extra Expense" value={state.extraExpense.value} onChange={this.handleChange} />
          { state.extraExpense.hasError && <p className="error-message">{state.extraExpense.errorMessage}</p> }
          <span className="defaultValue">(Default set at 10%)</span>
          </div></div>

          <div>
          <div className="label-with-help">
          <h5>Insured Value:</h5> 
          <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>The amount of insurance being purchased, calculated as shipment value + cost of freight + extra expense.</p>
              </div>
          </div>
          <h5 id="basicFee">${this.currencyFormat(state.insuredValue.value)}</h5>
          </div>

          <div className="rate-wrapper">
          <div className="label-with-help">
          <label htmlFor="cargoRate">Cargo Insurance Rate</label>
          <div className="hover-icon closer-placement"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>This rate can be found on your cargo insurance policy declaration page.</p>
              </div>
          </div>
          <input id="cargoRate" type="number" className={`${state.cargoRate.hasError ? "error" : ""}`} name="cargoRate" title="cargorate" placeholder="Cargo Rate" value={state.cargoRate.value} onChange={this.handleChange} />
          { state.cargoRate.hasError && <p className="error-message">{state.cargoRate.errorMessage}</p> }
          <span className="defaultValue">(Per $100 of Value)</span>
          </div>


          <div className="total-wrapper">
          <h3>Total Premium Estimate: <span>${this.currencyFormat(state.totalPremium.value)}</span></h3>
          </div>

          <div className="calculator-buttons">
            <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
            <div className="three-col">
              <button className="col color-button" type="submit">Calculate</button>
              <button className="col clear-button" type="reset" onReset={this.cancelCourse}>Clear</button>
              <h3 className="newCustomers col">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
            </div>
       
          </div>

        </form>
        </div>


        <div className="grid">
          {/* <div className="col-sm-6">
            <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
          </div> */}
          {/* { ( typeof window !== 'undefined' && state.totalPremium.value !== '0.00') &&
            <div className="col-sm-6">
               <Link to={`${printURL}print/`} state={state} className="printButton"><PrinterIcon />Print a Copy</Link>
            </div>
          } */}
          {/* <div className="col-sm-12">
            <h3 className="newCustomers">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
          </div> */}
        </div>
      </React.Fragment>
    )
  }
}

export default CalculatorFormInsurance