import React from "react"
import { graphql, Link } from "gatsby"

import '../css/calculator-form.css';


import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CalcIcon from '../images/calc-icon';


class CalculatorFormInsurance extends React.Component {

  constructor(props) {
    super(props);

    if(props.fields && typeof props.fields.shipmentValue !== 'undefined')
      this.state = props.fields;
    else
      this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    let message = this.validateField(name, value);

    if(value)
      value = parseFloat(value);

    if(message !== '') {
      this.setState({[name]: {value: value, hasError: true, errorMessage: message}});
    } else {
      this.setState({[name]: {value: value, hasError: false}});
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let state = this.state;
    let validSubmission = true;

    for (const elem in state) {
      let obj = state[elem];

      if(obj.isRequired !== true){
        continue;
      }

      let message = this.validateField(elem, obj.value);

      if(message !== '') {

        if(!obj.value && obj.value !== '')
          obj.value = parseFloat(obj.value)

        validSubmission = false;
        state[elem] = {value: obj.value, hasError: true, errorMessage: message};
      }
    }

    if(!validSubmission) {
      this.setState(state);
      return;
    }

    for (const elem in state) {
      let obj = state[elem];
    }

    state.bonds.value = (state.duties.value + state.taxes.value + state.fees.value) * .1;

    if(state.bonds.value < 50000)
      state.bonds.value = 50000;
    else {
      let value =  Math.ceil(state.bonds.value);
      let length = Math.log(value) * Math.LOG10E + 1 | 0;


      let round = 1;
      for (let i = 1; i < length; i++) {
        round += '0';
      }

      state.bonds.value = Math.ceil(value/round)*round
    }

    this.setState(state);
  }

  handleReset(event) {
    this.setState(this.defaultState());
  }

  validateField(name, value) {
    if(value === '')
        return'Please enter a number';

    let intValue = parseFloat(value.toString().replace(/[^a-zA-Z0-9]/g, ''));

    if(isNaN(intValue)) {
      return 'Please enter a valid number';
    }

    return ''
  }

  checkNumber(x) {

    // check if the passed value is a number
    if(typeof x == 'number' && !isNaN(x)){

      return true;

    } else {
      return false;
    }
  }

  defaultState() {
    return {
      duties: {
        value: 0,
        hasError: false,
        isRequired: true,
      },
      taxes: {
        value: 0,
        hasError: false,
        isRequired: true,
      },
      fees: {
        value: 0,
        hasError: false,
        isRequired: true,
      },
      bonds: {
        value: 0,
      },

    };
  }

  currencyFormat(num) {
    if(num === '0.00')
      return num;

    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    const { disclaimer, phoneNumber, printURL } = this.props;

    let state = this.state;


    return (
      <React.Fragment>
          <div className="calc-wrapper bond-form">
          <div className="calc-tab">
            <div className="title">
              <h2>U.S. Import Bond</h2>
              <h5>Calculator</h5>
            </div>
            <div className="icon"><CalcIcon /></div>
          </div>
        <form className="calcForm" onSubmit={this.handleSubmit} onReset={this.handleReset}>
          <div className="calc-inner-wrapper">
          <div className="label-with-help">
          <label htmlFor="duties">Duties:</label>
          <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>Estimate of total U.S. Customs duties on import shipments planned / expected for the next 12 months.</p>
              </div>
          </div>
          <div className="has-dollar-sign">
            <input id="duties" type="number" className={`${state.duties.hasError ? "error" : ""}`} name="duties" title="value" placeholder="Value" value={state.duties.value} onChange={this.handleChange} />
          </div>
          { state.duties.hasError && <p className="error-message">{state.duties.errorMessage}</p> }

          <div className="label-with-help">
          <label htmlFor="taxes">Taxes:</label>
          <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>Estimate of total U.S. Customs taxes on import shipments planned / expected for the next 12 months.</p>
              </div>
          </div>
          <div className="has-dollar-sign">
            <input id="taxes" type="number" className={`${state.taxes.hasError ? "error" : ""}`} name="taxes" title="cost" placeholder="Cost of Freight" value={state.taxes.value} onChange={this.handleChange} />
          </div>
          { state.taxes.hasError && <p className="error-message">{state.taxes.errorMessage}</p> }

          <div className="label-with-help">
          <label htmlFor="fees">Fees:</label>
          <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
              <div className="hover-help">
                <p>Estimate of total U.S. Customs fees on import shipments planned / expected for the next 12 months.</p>
              </div>
          </div>
          <div className="has-dollar-sign">
            <input id="fees" type="number" className={`${state.fees.hasError ? "error" : ""}`} name="fees" title="fees" placeholder="Extra Expense" value={state.fees.value} onChange={this.handleChange} />
          </div>
          { state.fees.hasError && <p className="error-message">{state.fees.errorMessage}</p> }
          </div>
         <div className="total-wrapper">
          <h3>Total Bond Estimate: <span>${this.currencyFormat(state.bonds.value)}</span></h3>
          </div>
          <div className="calculator-buttons">
          <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
            <div className="three-col">
              <button className="col color-button" type="submit">Submit</button>
              <button className="col clear-button" type="reset" onReset={this.cancelCourse}>Clear</button>
              <h3 className="newCustomers col">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
            </div>
          </div>

        </form>
        </div>

      </React.Fragment>
    )
  }
}

export default CalculatorFormInsurance