import React from "react";
import '../css/one-column.css';
import { graphql, useStaticQuery } from "gatsby";

import CalculatorFormInsurance from "../components/calculator-form-insurance";
import CalculatorFormBonds from "../components/calculator-form-bonds";
import CalculatorFormATA from "../components/calculator-form-ata";
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro';

const OneColumn = ({content, section, phoneLink, form}) => {
  const item = content.oneColumn[0];
  console.log(content);
  return (
    <div className="oneColWrapper" style={{ backgroundColor: content.backgroundColor }}>
    <div className={`one-column ${content.alignColumn} ${content.columnWidth}`}>

      {(() => {
        switch (item.__typename) {
          case 'WpTemplate_LandingPage_Templatelandingpage_Content_OneColumn_OneColumn_TextBlock':
            return (
              <div className="text-block" key={item.textBlockHeadline}>
                <h3 className="super">{section}</h3>
                <h2 className="headline">{item.textBlockHeadline}</h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: item.textBlockContent }} />
              </div>
            );

          case 'WpTemplate_LandingPage_Templatelandingpage_Content_OneColumn_OneColumn_Form':
            return (
              <div className="form-block" key={item.formFormId}>
                <h3>{item.formHeadline}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.formContent }} />
                <div className="form">
                  <GravityFormForm data={form} />
                </div>
              </div>
            );

          case 'WpTemplate_LandingPage_Templatelandingpage_Content_OneColumn_OneColumn_Calculator':
            return (
              <div className="calculator-block" key={item.calculatorCalculatorType}>
                {item.calculatorCalculatorType === 'bonds' ? (
                  <CalculatorFormBonds disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                ) : item.calculatorCalculatorType === 'insurance' ? (
                  <CalculatorFormInsurance disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                ) : item.calculatorCalculatorType === 'ata' ? (
                  <CalculatorFormATA disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                ) : null}
              </div>
            );

          case 'WpTemplate_LandingPage_Templatelandingpage_Content_OneColumn_OneColumn_Image':
            return (
              <div className="image-block" key={item.imageImage.localFile.publicURL}>
                <img className={`new-image ${section}`} src={item.imageImage.localFile.publicURL} alt={item.imageImage.altText} />
              </div>
            );

          default:
            return null;
        }
      })()}
    </div>
    </div>
  );
};

export default OneColumn;
