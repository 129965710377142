import React from "react"
import { graphql, Link } from "gatsby"

import '../css/calculator-form.css';

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrinterIcon from '../images/printer-icon';
import CalcIcon from '../images/calc-icon';

class CalculatorFormATA extends React.Component {
  
  debounce(func, delay) {
    let timerId;
    return function(...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  constructor(props) {
    super(props);

    if(props.fields && typeof props.fields.shipmentValue !== 'undefined')
      this.state = props.fields;
    else
      this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    this.resetEstimate = this.resetEstimate.bind(this);
    this.handleShippingChange = this.handleShippingChange.bind(this);

    
  }

  

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    let message = this.validateField(name, value);

    if(value)
      value = parseFloat(value);

    if(message !== '') {
      this.setState({[name]: {value: value, hasError: true, errorMessage: message}});
    } else {
      this.setState({[name]: {value: value, hasError: false}});
    }

    this.debounce(this.handleSubmit, 1000)(event); 

  }
  
  handleOptionChange(event) {
    const { name, value } = event.target;
  
    this.setState((prevState) => ({
      [name]: {
        ...prevState[name],
        value: event.target.value,
      },
    }));
  
    this.debounce(this.handleSubmit, 1000)(event);
  }
  

  // handleOptionChange(event) {

  //   this.setState({
  //     brazil: {
  //       value: event.target.value
  //     },
  //     road: {
  //       value: event.target.value
  //     }
  //   });
  //   this.debounce(this.handleSubmit, 1000)(event); 
  // }

  handleValueChange(event){
    this.handleChange(event);

    this.debounce(this.handleSubmit, 1000)(event); 
  }
  handleShippingChange(event) {
    const selectedValue = event.target.value;
    this.setState({
      shipping: selectedValue
    });
    // this.resetEstimate();
  }
  

  handleSubmit(event) {
    event.preventDefault();

    let state = this.state;
    let validSubmission = true;

    for (const elem in state) {
      let obj = state[elem];

      if(obj.isRequired !== true){
        continue;
      }

      let message = this.validateField(elem, obj.value);

      if(message !== '') {

        if(!obj.value && obj.value !== '')
          obj.value = parseFloat(obj.value)

        validSubmission = false;
        state[elem] = {value: obj.value, hasError: true, errorMessage: message};
      }
    }

    if(!validSubmission) {
      this.setState(state);
      return;
    }

    for (const elem in state) {
      let obj = state[elem];
    }
    
    // Calculate Basic Fee
    if (state.shipmentValue.value < 10000){
      state.basicFee.value = 255.00;
    }
    else if (state.shipmentValue.value >= 10000 && state.shipmentValue.value < 50000){
      state.basicFee.value = 300.00;
    }
    else if (state.shipmentValue.value >= 50000 && state.shipmentValue.value < 150000){
      state.basicFee.value = 365.00;
    }
    else if (state.shipmentValue.value >= 150000 && state.shipmentValue.value < 400000){
      state.basicFee.value = 425.00;
    }
    else if (state.shipmentValue.value >= 400000 && state.shipmentValue.value < 1000000){
      state.basicFee.value = 480.00;
    }
    else{
      state.basicFee.value = 545.00;
    }

    // Calculate Carnet Bond
    let bondCost = (state.shipmentValue.value * .01);
    console.log("BondCost: " + bondCost);

    if (state.brazil.value === "Yes"){
      bondCost = (bondCost * .55);
    }
    else {
      bondCost = (bondCost * .4);
    }
    if (bondCost < 50){
      this.state.carnetBond.value = 50;
    }
    else{
      this.state.carnetBond.value = bondCost;
    }

    // Calculate Carnet Insurance
    
    if (state.countries.value < 4){
      state.cargoInsurance.rate = .8;
    }
    else if (state.countries.value >=4 && state.countries.value < 8 ){
      state.cargoInsurance.rate = 1.05;
    }
    else if (state.countries.value >=8 && state.countries.value < 11 ){
      state.cargoInsurance.rate = 1.55;
    }
    else {
      state.cargoInsurance.rate = 1.8;
    }

   let insuranceCost = ((state.shipmentValue.value/100) * state.cargoInsurance.rate);

   if (insuranceCost < 50){
    state.cargoInsurance.value = 50;
   }
   else{
    state.cargoInsurance.value = insuranceCost;
   }

  // Calculate Road Fee
  if (state.road.value === "Yes"){
    if (state.roadType.value === "Individual"){
      state.roadFee.value = (state.basicFee.value * 1.5);
    }
    else{
      state.roadFee.value = state.basicFee.value;
    }
  }

   this.resetEstimate();

  //  state.totalEstimate.value = state.totalFee.value + state.shipping.value;
    // state.insuredValue.value = (state.shipmentValue.value + state.costFreight.value) * (state.extraExpense.value / 100 + 1);

    // state.totalPremium.value = (state.insuredValue.value / 100) * state.cargoRate.value;

    this.setState(state);
  }
  handleFinalSubmit(event){
    event.preventDefault(); // Prevent default form submission behavior

    let state = this.state;

    state.totalEstimate.value = state.basicFee.value + state.roadFee.value;

    if (document.getElementById('ata-carnet-bond-checkbox').checked) {
      state.totalEstimate.value += state.carnetBond.value;
      state.carnetBond.included = true;
    }
    if (document.getElementById('lost-document-checkbox').checked) {
      state.totalEstimate.value += state.lostDoc.value;
      state.lostDoc.included = true;
    }
    if (document.getElementById('ata-carnet-cargo-insurance-checkbox').checked) {
      state.totalEstimate.value += state.cargoInsurance.value;
      state.cargoInsurance.included = true;
    }
    if (document.getElementById('ata-carnet-shipping-checkbox').checked) {
      state.totalEstimate.value += state.expShip.value;
      state.expShip.included = true;
    }
    const shippingCost = parseFloat(this.state.shipping);

    state.totalEstimate.value += shippingCost;


    this.setState(state);
  }

  handleReset(event) {
    this.setState(this.defaultState());
  }

  validateField(name, value) {
    if(!value)
        return'Please enter a number';

    let intValue = parseFloat(value.toString().replace(/[^a-zA-Z0-9]/g, ''));

    if(isNaN(intValue)) {
      return 'Please enter a valid number';
    }

    return ''
  }

  checkNumber(x) {

    // check if the passed value is a number
    if(typeof x == 'number' && !isNaN(x)){

      return true;

    } else {
      return false;
    }
  }

  defaultState() {
    return {
      shipmentValue: {
        value: '',
        hasError: false,
        isRequired: true,
      },
      countries: {
        value: 1,
        hasError: false,
        isRequired: true,
      },
      brazil: {
        value: 'No',
        hasError: false,
        isRequired: true,
      },
      road: {
        value: 'No',
        hasError: false,
        isRequired: true,
      },
      roadType:{
        value: 'Individual',
        hasError: false,
        isRequired: true,
      },
      carnetBond: {
        value: 0.00,
        hasError: false,
        isRequired: true,
        included: false,
      },
      cargoRate: {
        value:'',
        hasError: false,
        isRequired: true,
      },
      basicFee: {
        value:'0.00',
      },
      totalFee: {
        value:'0.00',
      },
      totalEstimate: {
        value:'0.00',
      },
      lostDoc: {
        value: 35.00,
        included: false,
      },
      expShip:{
        value: 75.00,
        included: false,
      },
      roadFee:{
        value: 0.00,
        included: false,
      },
      cargoInsurance: {
        value: 0.00,
        rate: 0.00,
        included:false,
      },
      shipping: "10",
    };
  }
  resetEstimate(){
    let state = this.state;
    state.totalEstimate.value = '0.00';
    this.setState(state);
  }

  currencyFormat(num) {
    if(this.checkNumber(num)){
      if(num === '0.00')
        return num;

      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    }


  render() {
    const { disclaimer, phoneNumber, printURL, content } = this.props;

    console.log(disclaimer);
    let state = this.state;


    return (
      <React.Fragment>
        <div className="calc-wrapper ata-form">
          <div className="calc-tab">
            <div className="title">
              <h2>ATA Carnet</h2>
              <h5>Calculator</h5>
            </div>
            <div className="icon"><CalcIcon /></div>
          </div>
        <form className="calcForm ata-form" onSubmit={this.handleFinalSubmit} onReset={this.handleReset}>
          <div className="three-col">
            <div className="col first-col">
             <div className="label-with-help">
              <label htmlFor="shipmentValue">General List Value:</label>
                <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
                <div className="hover-help">
                  <p>The total value of goods to be shipped on the ATA Carnet.</p>
                </div>
              </div>
              <div className="has-dollar-sign">
                <input id="shipmentValue" type="number" className={`${state.shipmentValue.hasError ? "error" : ""}`} name="shipmentValue" title="value" placeholder="Value" value={state.shipmentValue.value} onChange={this.handleValueChange} />
              </div>
               { state.shipmentValue.hasError && <p className="error-message">{state.shipmentValue.errorMessage}</p> }
            </div>
            <div className="col second-col">
              <label htmlFor="countries">How Many Countries Visited?</label>
              <div className="">
                <input id="countries" type="number" className={`${state.countries.hasError ? "error" : ""}`} name="countries" title="countries" placeholder="Countries Visited" value={state.countries.value} onChange={this.handleChange} />
              </div>
              { state.countries.hasError && <p className="error-message">{state.countries.errorMessage}</p> }
            </div>
            <div className="col third-col">
            <h5>Will the goods go to India?</h5>
              <div className="radio-buttons row">  
              <div className="col">
                <input
                type="radio"
                name="brazil"
                id="yesOption"
                value="Yes"
                checked={state.brazil.value === 'Yes'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="yesOption">
                Yes
              </label>
              </div>
              <div className="col">
              <input
                type="radio"
                name="brazil"
                id="noOption"
                value="No"
                checked={state.brazil.value === 'No'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="noOption">
                No
              </label>
              </div>
            </div>
            </div>
          </div>

          <div className="forty-sixty-column">
          <div className="col forty-col no-border stacked-col">
            <h5>Will the trip include road vehicles?</h5>
              <div className="radio-buttons row">  
              <div className="col">
                <input
                type="radio"
                name="road"
                id="road"
                value="Yes"
                checked={state.road.value === 'Yes'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="road">
                Yes
              </label>
              </div>
              <div className="col">
              <input
                type="radio"
                id="noRoad"
                name="road"
                value="No"
                checked={state.road.value === 'No'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="noRoad">
                No
              </label>
              </div>
            </div>
            </div>
            {state.road.value === 'Yes' && (
            <div className="col sixty-col">
            <h5>Are you shipping as an individual or corporation? </h5>
              <div className="radio-buttons row">  
              <div className="col">
                <input
                type="radio"
                name="roadType"
                id="indiv"
                value="Individual"
                checked={state.roadType.value === 'Individual'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="indiv">
                Individual
              </label>
              </div>
              <div className="col">
              <input
                type="radio"
                name="roadType"
                id="corp"
                value="Corporation"
                checked={state.roadType.value === 'Corporation'} // Set checked state based on selected option
                onChange={this.handleOptionChange} // Call handleOptionChange function on change
              />
              <label htmlFor="corp">
                Corporation
              </label>
              </div>
            </div>
            </div>
            )}
          </div>
          
          <div className="forty-sixty-column">
            <div className="col forty-col stacked-col">
            <div id="ata-carnet-bond" className="label-with-help">
                <label htmlFor="ata-carnet-bond-checkbox">
                  <input defaultChecked
                    type="checkbox"
                    id="ata-carnet-bond-checkbox"
                    onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                  />
                  <p>Include ATA Carnet Bond for&nbsp;<span>${this.currencyFormat(state.carnetBond.value)}</span></p>
                </label>
                <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
                <div className="hover-help">
                  <p>The United States Council for International Business (USCIB) generally requires a security/bond amount of 40% of the total General List value. In some cases where vehicles are traveling on an ATA Carnet USCIB requires 100% to 150% of the value for the security deposit.</p>
                </div>
              </div>
              <div className="stacked-col">
              <div className="label-with-help">
                <h5>Basic Fee:</h5>
                <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
                <div className="hover-help">
                  <p>All ATA Carnets are assessed a Basic fee. The Basic fee is calculated based upon the total value of the General List.</p>
                </div>
                </div>
                <h5 id="basicFee"><span>${this.currencyFormat(this.state.basicFee.value)}</span></h5>
               </div>
               <div id="shipping-costs">
                  <h5>Shipping & Handling Fee</h5>
                  <select value={this.state.shipping} onChange={this.handleShippingChange}>
                    <option value="20">Standard S&H Fee - $20</option>
                    {/* <option value="75">Expedited Shipping Fee - $75</option> */}
                    <option value="6">Handling Fee (Customer Pickup) - $6</option>
                    <option value="150">Same Day Pickup - $150</option>
                  </select>
                </div>
             </div>
             <div className="col sixty-col stacked-col" id="add-ons">
              <h5><strong>Optional Add-ons</strong></h5>
             
              <div id="lost-document" className="label-with-help">
                <label htmlFor="lost-document-checkbox">
                  <input
                    type="checkbox"
                    id="lost-document-checkbox"
                    onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                  />
                  Include Lost Document Coverage for&nbsp;<span>${this.state.lostDoc.value}</span>
                </label>
                <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
                <div className="hover-help">
                  <p>When shipping goods on an ATA Carnet, Customs requires the paper document to enter or exit a foreign country and to re-enter the United States. If the ATA Carnet is lost, the holder must secure a replacement to clear customs at full cost. Lost Document Coverage helps avoid costly replacement fees and inconvenient delays.</p>
                </div>
              </div>
              <div id="ata-carnet-cargo-insurance" className="label-with-help">
                <div><label htmlFor="ata-carnet-cargo-insurance-checkbox">
                  <input
                    type="checkbox"
                    id="ata-carnet-cargo-insurance-checkbox"
                    onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                  />
                  Include ATA Carnet Cargo Insurance for&nbsp;<span>${this.currencyFormat(state.cargoInsurance.value)}</span>
                </label></div>
                <div className="hover-icon"><FontAwesomeIcon icon={faInfoCircle} /></div>
                <div className="hover-help">
                  <p>All Risk” coverage for new and used goods, including transit to and from a foreign ATA Carnet member country and while in storage. Provides duty reimbursement for lost or stolen goods. Includes coverage for goods checked or hand-carried luggage on a commercial airline.</p>
                </div>
              </div>
              <div id="ata-carnet-shipping">
                <label htmlFor="ata-carnet-shipping-checkbox">
                  <input
                    type="checkbox"
                    id="ata-carnet-shipping-checkbox"
                    onChange={this.resetEstimate} // Call resetEstimate when checkbox value changes
                  />
                  Include Expedited Shipping for&nbsp;<span>${this.state.expShip.value}</span>
                </label>
              </div>
             </div>
          </div>

          {/* <h4>Total Fee: <span>${this.currencyFormat(state.totalFee.value)}</span></h4> */}

          

          <div className="total-wrapper">
          <h3>Total Estimate: <span>${this.currencyFormat(state.totalEstimate.value)}</span></h3>
          </div>

          {/* <div id="processing-costs">
            <h4>Additional Processing Costs to Consider:</h4>
            <p>Additional General List pages (per side): $12.00 per side of page.</p>
            <p>Additional sets - Counterfoils and Vouchers: $25.00 per additional set.</p>
          </div> */}
          <div className="calculator-buttons">
          <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
            <div className="three-col">
              <button className="col color-button" type="submit">Calculate</button>
              <button className="col clear-button" type="reset" onReset={this.cancelCourse}>Clear</button>
              <h3 className="newCustomers col">New Customers Please Call: <a href={"tel:"+phoneNumber}>{phoneNumber}</a></h3>
            </div>
       
          </div>
        </form>
        </div>


        {/* <div className="grid">
          <div className="col-sm-6">
           
          </div>
          { ( typeof window !== 'undefined' && state.totalEstimate.value !== '0.00') &&
            <div className="col-sm-6">
               <Link to={`${printURL}print/`} state={state} className="printButton"><PrinterIcon />Print a Copy</Link>
            </div>
          }
          <div className="col-sm-12">
           
          </div>
        </div> */}
       
      </React.Fragment>
    )
  }
}

export default CalculatorFormATA