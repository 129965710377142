import React from "react";
import { Link } from "gatsby";
import '../css/two-column-2.css';
import { graphql, useStaticQuery } from "gatsby";


import CalculatorFormInsurance from "../components/calculator-form-insurance";
import CalculatorFormBonds from "../components/calculator-form-bonds";
import CalculatorFormATA from "../components/calculator-form-ata";
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro'


const renderContentBlock = (item, section, phoneLink, form) => {
  switch (item.__typename) {
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_LeftColumn_TextBlock':
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_RightColumn_TextBlock':
      return (
        <div className="text-block" key={item.textBlockHeadline}>
         <h3 className="super">{section}</h3>
          <h2 className="headline">{item.textBlockHeadline}</h2>
          <div className="content" dangerouslySetInnerHTML={{ __html: item.textBlockContent }} />
        </div>
      );
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_LeftColumn_Form':
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_RightColumn_Form':
      return (
        <div className="form-block" key={item.formFormId}>
          <h3>{item.formHeadline}</h3>
          <div dangerouslySetInnerHTML={{ __html: item.formContent }} />
          <div className="form">
          <GravityFormForm data={form} />
          </div>
        </div>
      );
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_LeftColumn_Calculator':
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_RightColumn_Calculator':
      return (
        <div className="calculator-block" key={item.calculatorCalculatorType}>
           { item.calculatorCalculatorType === 'bonds' 
                  ? <CalculatorFormBonds disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                  : item.calculatorCalculatorType === 'insurance'
                    ? <CalculatorFormInsurance disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                    : item.calculatorCalculatorType === 'ata'
                      ? <CalculatorFormATA disclaimer={item.calculatorDisclaimer} phoneNumber={phoneLink} />
                      : null // Render null if none of the options match
                }
        </div>
      );
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_LeftColumn_Image':
    case 'WpTemplate_LandingPage_Templatelandingpage_Content_TwoColumn_RightColumn_Image':
      return (
        <div className="image-block" key={item.imageImage.publicURL}>
          <img className={`new-image ${section}`} src={item.imageImage.localFile.publicURL} alt={item.imageImage.altText} />
        </div>
      );
    default:
      return null;
  }
};

const TwoColumn = ({ content, section, phone, form }) => {

  console.log(content);
  // console.log(form);
  return (
    <div className={`two-column ${content.columnSplit}`} style={{ backgroundColor: content.backgroundColor }}>
      <div className="left-col">
        {content.leftColumn.map((item) => renderContentBlock(item, section, phone, form))}
      </div>
      <div className="right-col">
        {content.rightColumn.map((item) => renderContentBlock(item, section, phone, form))}
      </div>
    </div>
  );
}

export default TwoColumn;
