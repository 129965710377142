import React from 'react';
import styled from 'styled-components';


export default function calcIcon() {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="100%" viewBox="0 0 516 594"  >

 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M102.341599,67.896423 
   C104.651215,67.526985 106.555069,67.033638 108.459114,67.032936 
   C209.106598,66.995651 309.754456,67.114876 410.400940,66.796913 
   C419.099060,66.769432 427.550293,75.273453 427.994415,84.152534 
   C428.035980,84.983627 428.000000,85.818604 428.000000,86.651779 
   C428.000031,227.624924 427.944061,368.598145 428.097107,509.571136 
   C428.110596,522.002197 419.009460,530.073608 407.527924,530.061279 
   C308.713531,529.954651 209.898941,529.972107 111.084473,530.038635 
   C99.941345,530.046082 90.929901,522.048157 90.937965,510.183472 
   C91.034142,368.710480 90.988411,227.237396 91.030380,85.764359 
   C91.032761,77.745262 94.433083,71.547966 102.341599,67.896423 
 M394.500000,220.000000 
   C402.597260,220.000000 410.694489,220.000000 419.000000,220.000000 
   C419.000000,195.458817 419.000000,171.489075 419.000000,147.519348 
   C419.000000,127.692513 418.963257,107.865562 419.020691,88.038895 
   C419.039856,81.418510 415.325531,75.818237 407.407196,75.838173 
   C309.106689,76.085777 210.805328,75.999992 112.504204,76.000015 
   C103.307541,76.000015 100.004723,79.316704 100.003143,88.626541 
   C99.995964,130.946014 99.997681,173.265472 100.009987,215.584946 
   C100.010399,217.013306 100.158081,218.441650 100.244308,220.000000 
   C198.257919,220.000000 295.878967,220.000000 394.500000,220.000000 
 M185.500000,369.000000 
   C157.083710,369.000000 128.667419,369.000000 100.000000,369.000000 
   C100.000000,371.342285 100.000000,373.148071 100.000000,374.953857 
   C100.000000,418.759033 100.008148,462.564209 99.994377,506.369385 
   C99.990852,517.575378 104.051880,520.915710 114.706467,520.945801 
   C160.010315,521.073547 205.314728,521.002136 250.618942,520.990173 
   C252.044083,520.989746 253.469177,520.842712 254.699982,520.774780 
   C254.699982,469.956909 254.699982,419.574280 254.699982,369.000000 
   C231.767014,369.000000 209.133514,369.000000 185.500000,369.000000 
 M399.499573,369.000000 
   C354.424683,369.000000 309.349762,369.000000 264.228333,369.000000 
   C264.228333,419.899261 264.228333,470.281494 264.228333,520.496338 
   C264.585205,520.757080 264.713654,520.933167 264.842194,520.933228 
   C311.978058,520.963379 359.114136,521.055237 406.249664,520.935120 
   C415.233154,520.912231 418.995270,516.535645 418.996735,507.411316 
   C419.003937,462.773346 419.002136,418.135376 418.990356,373.497406 
   C418.989990,372.062469 418.846649,370.627625 418.759644,369.000000 
   C412.448975,369.000000 406.474152,369.000000 399.499573,369.000000 
 M264.000000,323.500000 
   C264.000000,335.263855 264.000000,347.027710 264.000000,358.712524 
   C315.978424,358.712524 367.380585,358.712524 418.712158,358.712524 
   C418.712158,315.355042 418.712158,272.285614 418.712158,229.287460 
   C367.021606,229.287460 315.619446,229.287460 264.000000,229.287460 
   C264.000000,260.549622 264.000000,291.524811 264.000000,323.500000 
 M100.000000,299.500000 
   C100.000000,319.262085 100.000000,339.024170 100.000000,358.713287 
   C151.976868,358.713287 203.379044,358.713287 254.712906,358.713287 
   C254.712906,315.356567 254.712906,272.287170 254.712906,229.286713 
   C203.023132,229.286713 151.620956,229.286713 100.000000,229.286713 
   C100.000000,252.548492 100.000000,275.524231 100.000000,299.500000 
 z"/>
 
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M240.000000,107.000000 
   C288.474518,107.000000 336.449036,107.000000 384.711609,107.000000 
   C384.711609,134.951752 384.711609,162.689224 384.711609,190.713806 
   C301.378845,190.713806 217.978012,190.713806 134.288422,190.713806 
   C134.288422,163.048340 134.288422,135.310867 134.288422,107.000000 
   C169.311966,107.000000 204.405975,107.000000 240.000000,107.000000 
 z"/>
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M179.000000,451.111206 
   C179.000000,449.180481 179.000000,447.738251 179.000000,446.078125 
   C167.228455,446.078125 155.944077,446.078125 144.330780,446.078125 
   C144.330780,443.133148 144.330780,440.392395 144.330780,437.255920 
   C155.513657,437.255920 166.928207,437.255920 178.740204,437.255920 
   C178.740204,424.473663 178.740204,412.066986 178.740204,399.331604 
   C182.194916,399.331604 185.267715,399.331604 188.742477,399.331604 
   C188.742477,411.517609 188.742477,423.930023 188.742477,436.379944 
   C201.320343,437.077942 213.523880,437.755188 226.210495,438.459229 
   C225.562912,441.530701 225.117828,443.641754 224.632294,445.944641 
   C212.905960,445.944641 201.273392,445.944641 189.242050,445.944641 
   C189.242050,457.544220 189.242050,468.951752 189.242050,480.677948 
   C185.787613,480.677948 182.712082,480.677948 179.000000,480.677948 
   C179.000000,471.117249 179.000000,461.358521 179.000000,451.111206 
 z"/>
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M335.000000,416.000000 
   C350.142120,416.000000 364.784210,416.000000 379.712646,416.000000 
   C379.712646,419.278992 379.712646,422.353882 379.712646,425.717590 
   C352.721527,425.717590 325.648621,425.717590 298.287384,425.717590 
   C298.287384,422.721069 298.287384,419.646210 298.287384,416.000000 
   C310.299408,416.000000 322.399719,416.000000 335.000000,416.000000 
 z"/>
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M372.907959,461.000000 
   C375.044800,461.000000 376.693817,461.000000 378.671082,461.000000 
   C378.671082,463.863495 378.671082,466.598358 378.671082,469.672455 
   C351.755249,469.672455 324.610504,469.672455 297.232208,469.672455 
   C297.232208,466.949097 297.232208,464.217010 297.232208,461.000000 
   C322.334778,461.000000 347.377441,461.000000 372.907959,461.000000 
 z"/>
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M368.990936,303.000000 
   C345.850861,303.000000 323.209625,303.000000 300.284607,303.000000 
   C300.284607,300.045502 300.284607,297.306396 300.284607,294.280029 
   C327.280151,294.280029 354.355408,294.280029 381.715820,294.280029 
   C381.715820,296.955292 381.715820,299.694366 381.715820,303.000000 
   C377.704468,303.000000 373.597137,303.000000 368.990936,303.000000 
 z"/>
 <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
   d="
 M190.772919,297.728149 
   C188.116974,299.579529 190.078766,300.560516 191.090485,301.577728 
   C197.196854,307.717407 203.333084,313.827362 209.458755,319.947845 
   C214.039154,324.524384 214.040176,324.526184 209.405945,329.092285 
   C208.711487,329.776550 207.996643,330.440094 206.796875,331.584961 
   C198.662521,323.096069 190.657333,314.741943 182.150558,305.864410 
   C175.132965,313.051575 168.783585,319.597809 162.383911,326.094482 
   C157.521942,331.030182 157.495148,331.000458 152.560516,326.043854 
   C152.091034,325.572296 151.583679,325.138489 150.533813,324.171021 
   C158.819366,316.124634 167.006577,308.173767 175.465179,299.959351 
   C166.334015,291.051331 157.435532,282.370331 148.804916,273.950653 
   C149.111710,272.882141 149.105026,272.484161 149.300720,272.284698 
   C156.730988,264.711121 154.831329,265.494263 161.559555,272.088440 
   C167.386627,277.799408 173.305145,283.438202 178.799438,289.459106 
   C181.771500,292.716034 183.560043,292.193542 186.312057,289.298096 
   C193.534790,281.698975 201.042221,274.370422 208.831924,266.536072 
   C210.723679,268.977875 212.498093,271.268188 214.553314,273.920990 
   C207.189850,281.289520 199.102631,289.382294 190.772919,297.728149 
 z"/></svg>
  )
}